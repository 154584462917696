import React from 'react';
import {HiArrowNarrowRight} from 'react-icons/hi';
import { Link } from "react-scroll";

const Home = () => {
    return (
			<div name='home' className='w-full h-screen bg-[#0a192f]'>
				{/* container */}
				<div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
					<p className='text-white'>Hi, my name is</p>
					<h1 className='text4xl sm:text-7xl font-bold text-[#7af050]'>
						Andrew
					</h1>
					<h2 className='text4xl sm:text-7xl font-bold text-[#0680de]'>
						I build things for the web
					</h2>
					<p className='text-[#8892b0] py-4 max-w-[700px]'>
						I'm a Full Stack Developer specializing in building exceptional
						digital experiences.
					</p>
					<div>
						<div className='text-white group border-2 px-8 py-4 my-2 flex items-center w-40 hover:bg-[#7af050] hover:border-bg-[#7af050]'>
							<Link to='work'>View Work</Link>
						</div>
					</div>
				</div>
			</div>
		);
}

export default Home;