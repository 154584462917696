import React from 'react';
import ClothingStore from "../assets/projects/ClothingStore2.jpg";

const Work = () => {
    return (
			<div name='work' className='w-full md:screen text-gray-300 bg-[#0a192f]'>
				<div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
					<div className='pb-8'>
						<p className='text-4xl font-bold inline border-b-4 text-gray-300 border-[#7af050]'>
							Work
						</p>
						<p className='py-6'>// Check out some of my recent work</p>
					</div>
					{/* Container */}
					<div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
						{/* Grid Item */}
						<div
							style={{ backgroundImage: `url(${ClothingStore})` }}
							className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
							{/* Hover Effects */}
							<div className='opacity-0 group-hover:opacity-100'>
								<div>
									<span className='text-2xl font-bold text-white tracking-wider'>
										Clothing Store
									</span>
									<div className='pt-8 text-center'>
										<a href='/'>
											<button
												type='button'
												onClick={(e) => {
													e.preventDefault();
													window.location.href =
														"https://andrewsclothingstore.netlify.app";
												}}
												className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text.lg'>
												Demo
											</button>
										</a>
										<a href='/'>
											<button
												type='button'
												onClick={(e) => {
													e.preventDefault();
													window.location.href =
														"https://drive.google.com/file/d/1uViqn7CGHB97DUD-SiKIjBwI4N1NKOFD/view?usp=share_link";
												}}
												className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text.lg'>
												Preview
											</button>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
};

export default Work;