import React from "react";

import Python from "../assets/Python.png";
import ReactJS from "../assets/react.png";
import JavaScript from "../assets/javascript.png";
import Go from "../assets/Go.png";
import Django from "../assets/Django.png";
import PostgreSQL from "../assets/PostgreSQL.png";
import CSS from "../assets/css.png";
import TYPESCRIPT from "../assets/typescript.png";
import Node from "../assets/node.png";
const Skills = () => {
    return (
			<div name='skills' className='w-full h-screen bg-[#0a192f] text-gray-300'>
				{/* container */}
				<div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
					<div>
						<p className='text-4xl font-bold inline border-b-4 border-[#7af050] '>
							Skills
						</p>
						<p className='py-4'>
							/* These are the technologies I've worked with */
						</p>
					</div>

					<div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
						<div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
							<img className='w-20 mx-auto' src={Python} alt='Python' />
							<p className='my-4'>Python</p>
						</div>
						<div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
							<img className='w-20 mx-auto' src={Go} alt='Go' />
							<p className='my-4'>Go</p>
						</div>
						<div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
							<img className='w-20 mx-auto' src={ReactJS} alt='ReactJS' />
							<p className='my-4'>ReactJS</p>
						</div>
						<div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
							<img className='w-20 mx-auto' src={JavaScript} alt='JavaScript' />
							<p className='my-4'>JavaScript</p>
						</div>
						<div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
							<img className='w-20 mx-auto' src={Django} alt='Django' />
							<p className='my-4'>Django</p>
						</div>
						<div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
							<img className='w-20 mx-auto' src={PostgreSQL} alt='PostgreSQL' />
							<p className='my-4'>PostgreSQL</p>
						</div>
						<div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
							<img className='w-20 mx-auto' src={TYPESCRIPT} alt='TYPESCRIPT' />
							<p className='my-4'>TypeScript</p>
						</div>
						<div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
							<img className='w-20 mx-auto' src={Node} alt='Node' />
							<p className='my-4'>Node</p>
						</div>
					</div>
				</div>
			</div>
		);
};

export default Skills;